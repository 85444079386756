import rangeDatePicker from "@/components/dateTime/rangeTime.vue";
import moment from "moment";

export default {
	data() {
		return {
			allowedDates: []
		}
	},
	watch: {
		storePriceDialog: {
			handler(v) {
				if (v) {
					this.allowedDates = [...new Array(180)].map((i, idx) => moment().startOf("day").subtract(-idx, "days").format('YYYY-MM-DD'));
				}
			},
			immediate: true
		}
	},
	methods: {
		getDeploy(filed, label) {
			return {
				row: [
					{
						cols: 12,
						subassembly: rangeDatePicker,
						property: ['startDate', 'endDate'],
						deconstruction: function (args) {
							return args.split(" ~ ")
						},
						connector: {
							props: {
								label: "日期范围",
								required: true,
								allowedDates: this.allowedDates
							}
						}
					},
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'week',
						connector: {
							props: {
								label: "选择周几",
								items: [
									{code: 1, desc: '周一'},
									{code: 2, desc: '周二'},
									{code: 3, desc: '周三'},
									{code: 4, desc: '周四'},
									{code: 5, desc: '周五'},
									{code: 6, desc: '周六'},
									{code: 0, desc: '周日'}
								],
								multiple: true,
								chips: true
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: filed,
						connector: {
							props: {
								label: label,
								required: true
							}
						}
					}
				]
			}
		},
		saveTableFiled(field, value, item) {
			item[field] = value;
			item['startDate'] = item.date;
			item['endDate'] = item.date;
			item['price'] = item.rate;
			this.axios.post(this.api.save, item).then(r => {
				// this.list();
			});
		}
	}
}
