<template>
	<div>
		<common-table
				:itemScope="['lsell', 'storePrice', 'lreal']"
				:headers="headers"
				:listData="listData"
				@changePage="changePage"
				:loading="loading"
				:btnAction="true"
				@search="search"
				@remove="remove"
				@save="save"
				@edit="edit"
				ref="ct"
				:rightConfig="rightConfig"
				:leftConfig="leftConfig"
				pageTitle="票型"
				:listDeploy="listDeploy"
				@beforeOpenAddDialog="beforeOpenAddDialog"
		>
			<template v-slot:lsell="{ item }">
				<w-switches
						inset
						label=""
						color="var(--v-primary-base)"
						@change="updateStatus(item.sqlid)"
						v-model="item.lsell" hide-details dense/>
			</template>

			<template v-slot:lreal="{ item }">
				{{ item.lreal ? '是' : '否' }}
			</template>

			<template v-slot:storePrice="{ item }">
				<v-icon class="iconfont ml-2" color="primary" size="24" @click="storePrice(item.code)">iconfont icon-chakan-fill
				</v-icon>
			</template>
		</common-table>
		<template v-if="storePriceDialog">
			<store-price
					:code="typeCode"
					:storePriceDialog.sync="storePriceDialog"
					@closeStorePriceDialog="storePriceDialog=false"/>
		</template>
	</div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/productManage/ticketType.js';
import editorText from "@/components/editorText/editorText.vue";
import storePrice from "./ticketTypeStorePrice.vue";
import commonData from "@/api/common-data.js";

export default {
	mixins: [commonCurd],
	components: {storePrice},
	data() {
		return {
			api,
			// 排序字段，不填默认sqlid
			sortname: 'seq',
			typeCode: '',
			storePriceDialog: false,
			rightConfig: [
				{label: '新建', icon: 'zengjia', event: 'add'}
			],
			leftConfig: [{type: 'input', label: '输入票型代码/名称', property: 'searchkey'}],
			headers: [
				{text: '票型代码', value: 'code'},
				{text: '票型名称', value: 'description'},
				{text: '实名制', value: 'lreal'},
				{text: '月销笔数', value: 'pinnum'},
				{text: '排序号', value: 'seq'},
				{text: '库存&价格', value: 'storePrice'},
				{text: '预订状态', value: 'lsell'},
				{text: '操作', value: 'actions', width: 120}
			],
			listDeploy: {
				row: [
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'code',
						hasEditDisabledProperty: true,
						connector: {
							props: {
								required: true,
								label: "票型代码"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'description',
						connector: {
							props: {
								required: true,
								label: "票型名称"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'outcode',
						connector: {
							props: {
								required: false,
								label: "闸机票型编码"
							}
						}
					},
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'lsell',
						connector: {
							props: {
								required: true,
								label: "票型状态",
								items: [{code: true, desc: '可销售'}, {code: false, desc: '不可销售'}]
							}
						}
					},
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'rulecode',
						connector: {
							props: {
								required: true,
								label: "业务规则",
								items: []
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'showprice',
						connector: {
							props: {
								required: false,
								type: 'number',
								label: "市场价格"
							}
						}
					},
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'showmode',
						connector: {
							props: {
								required: true,
								label: "销量显示",
								items: [{code: 0, desc: '无'}, {code: 1, desc: '月销量'}, {code: 2, desc: '剩余库存'}]
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'pinnum',
						connector: {
							props: {
								label: "月销笔数"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'seq',
						connector: {
							props: {
								label: "排序号码",
								type: 'number'
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'expireday',
						connector: {
							props: {
								label: "检票有效期-默认0为当天有效,1为两天",
								type: 'number'
							}
						}
					},
          {
            cols: 6,
            subassembly: "VTextField",
            property: 'maxidage',
            connector: {
              props: {
                label: "身份证购票年龄限制,默认为0不限制,70代表必须满70岁可购买",
                type: 'number'
              }
            }
          },
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'reason',
						connector: {
							props: {
								required: true,
								label: "关闭购买原因",
								items: [],
								chips: true
							}
						}
					},
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'overreason',
						connector: {
							props: {
								required: true,
								label: "售罄原因",
								items: [],
								chips: true
							}
						}
					},
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'usetips',
						connector: {
							props: {
								required: false,
								label: "核销提示",
								items: [],
								chips: true
							}
						}
					},
					{
						cols: 3,
						subassembly: "WSwitches",
						property: 'lreal',
						connector: {
							props: {
								required: true,
								label: "实名制",
								inset: true
							}
						}
					},
					{
						cols: 3,
						subassembly: "WSwitches",
						property: 'lo2m',
						connector: {
							props: {
								required: true,
								label: "一证多票",
								inset: true
							}
						}
					},
					{
						cols: 3,
						subassembly: "WSwitches",
						property: 'lshowtime',
						connector: {
							props: {
								required: true,
								label: "时间段购票",
								inset: true
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'allowtips',
						connector: {
							props: {
								required: false,
								label: "限定购买原因"
							}
						}
					},
					{
						cols: 12,
						subassembly: "VTextField",
						property: 'allowids',
						connector: {
							props: {
								required: false,
								label: "限定身份证白名单,以,号分割,留空则不限制,*号限制只允许身份证提交"
							}
						}
					}
					/*{
						cols: 12,
						subassembly: 'div',
						connector: {
							class: 'form-row-title',
							domProps: {
								innerHTML: '购买须知'
							}
						}
					},
					{
						cols: 12,
						subassembly: editorText,
						property: 'richtext'
					}*/
				]
			}
		}
	},
	methods: {
		// 库存&价格
		storePrice(item) {
			this.storePriceDialog = true;
			this.typeCode = item;
		},
		createdExtend() {
			this.fixedParams.group = this.$route.query.value;
		},
		beforeSave(form) {
			form.groupid = this.$route.query.value;
		},
		beforeOpenAddDialog() {
			this.axios.post(
					commonData.select,
					commonData.getSelectList({keyname: "rules,closereason,usetips"})
			).then((res) => {
						if (res?.data) {
							this.listDeploy.row[4].connector.props.items = res.data[0].values;
							this.listDeploy.row[11].connector.props.items = res.data[1].values;
							this.listDeploy.row[12].connector.props.items = res.data[1].values;
							this.listDeploy.row[13].connector.props.items = res.data[2].values;
						}
					}
			);
		}
	}
}
</script>

<style scoped lang="scss">

</style>
